import './App.css';
import HeaderComponent from './components/HeaderComponent';
import CarouselComponent from './components/CarouselComponent';
import FooterComponent from './components/FooterComponent';
import BodyComponent from './components/BodyComponent';
import GoogleMapComponent from './components/GoogleMapComponent ';
import FoodCategory from './components/FoodCategory';
import {Container } from 'react-bootstrap';


function App() {
  return (
    <div>
      <Container className="container-xl">
        <HeaderComponent/>
        <CarouselComponent/>
        <BodyComponent/>
        <FoodCategory category={{name:"Adresse und Anfahrt", adresse :"Kärntner Str. 167, 8053 Graz"}}/>
        <GoogleMapComponent/>
        <FooterComponent/>
      </Container>

    </div>
  );
}

export default App;
