import React from "react";
import PizzaBoxComponent from "./PizzaBoxComponent";
import FoodCategory from "./FoodCategory";
import parse from "html-react-parser";
const BodyComponent = () => {
  const doners = [
    {
      name: "Innenputz",
      ingredients:
        "Wir gestalten Innenräume nach Ihren Vorstellungen. Ob glatt, strukturiert oder dekorativ – wir verwandeln Ihre Wände in echte Hingucker.",
    },
    {
      name: "Außenputz",
      ingredients:
        "Unsere Außenputzexperten schützen Ihr Gebäude vor den Elementen und verleihen ihm gleichzeitig ein ästhetisches Erscheinungsbild.",
    },
    {
      name: "Sanierung und Reparatur",
      ingredients:
        "Wir sind auch darauf spezialisiert, beschädigten Putz zu reparieren und verleihen Ihren Räumen so neuen Glanz.",
    },
    {
      name: "Beratung",
      ingredients:
        "Unser Team steht Ihnen von Anfang an beratend zur Seite, um sicherzustellen, dass Ihr Projekt Ihren Bedürfnissen und Wünschen entspricht..",
    },
  ];
  const categorydonerList = { name: "LEISTUNGEN" };

  const donerList = doners.map((food) => <PizzaBoxComponent food={food} />);
  return (
    <div class="container-fluid p-0">
      <p>
        Herzlich willkommen bei LJ BAU GmbH! Wir freuen uns, Sie auf unserer
        Willkommensseite begrüßen zu dürfen. Als Ihr zuverlässiger Partner für
        Innen- und Außenputzarbeiten möchten wir Ihnen einen Einblick in unsere
        Firma und unsere Leistungen geben. Unsere Expertise liegt im Bereich des
        Putzhandwerks, und wir sind stolz darauf, unsere Kunden mit hochwertigen
        und langlebigen Lösungen für Innen- und Außenputz zu versorgen. Egal, ob
        Sie Ihr Zuhause renovieren, ein neues Gebäude errichten oder einfach nur
        frischen Wind in Ihre Räumlichkeiten bringen möchten – wir sind für Sie
        da. Unsere engagierten Fachleute verfügen über jahrelange Erfahrung in
        der Branche und setzen ihr Wissen und ihre Fähigkeiten ein, um Ihr
        Projekt erfolgreich umzusetzen. Wir verwenden nur die besten Materialien
        und modernste Techniken, um sicherzustellen, dass Ihr Putzprojekt den
        höchsten Standards entspricht.
      </p>
      <FoodCategory category={categorydonerList} />
      {donerList}
    </div>
  );
};
export default BodyComponent;
