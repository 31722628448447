import React from "react";

const GoogleMapComponent = () => {
  return (
    <div>
      <iframe
        //src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.521260322283!2d106.8195613507864!3d-6.194741395493371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5390917b759%3A0x6b45e67356080477!2sPT%20Kulkul%20Teknologi%20Internasional!5e0!3m2!1sen!2sid!4v1601138221085!5m2!1sen!2sid"
        //src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d678.5895235847524!2d15.347104369673469!3d47.1312484981969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476e315c63b61a9b%3A0x8ac601ba9dc51fc2!2sFriedhofgasse%2030%2C%208101%20Gratkorn!5e0!3m2!1sde!2sat!4v1681580581141!5m2!1sde!2sat"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d347998.6122762013!2d15.414982!3d47.045349!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476e3545791e1223%3A0x5642b4d8238f1d3b!2sK%C3%A4rntner%20Str.%20167%2C%208053%20Graz%2C%20%C3%96sterreich!5e0!3m2!1sde!2sus!4v1696425129142!5m2!1sde!2sus"
        width="100%"
        height="400px"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      />
    </div>
  );
};
export default GoogleMapComponent;
