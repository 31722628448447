import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons/faChevronCircleRight";
const PizzaBoxComponent = ({ food }) => {
  return (
    <div style={{ display: "block", padding: 10 }}>
      <Row style={{ borderBottomStyle: "SOLID", display: "flex" }}>
        <Col md={12}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "32px", color: "red", fontSize: "28px" }}>
              <FontAwesomeIcon icon={faChevronCircleRight}></FontAwesomeIcon>
            </div>
            <div
              style={{
                paddingTop: "0px",
                paddingRight: "40px",
                paddingBottom: "0px",
                paddingLeft: "0px",
              }}
            >
              <div
                style={{
                  paddingTop: "0px",
                  paddingRight: "40px",
                  paddingBottom: "0px",
                  paddingLeft: "0px",
                  fontSize: "28px",
                  fontFamily: "Kaushan Script",
                  color: "#0c5629",
                }}
              >
                {food.name}
                <sub style={{ fontSize: "12px", paddingRight: "" }}>
                  {food.allergene}
                </sub>
              </div>
              <div
                style={{
                  paddingTop: "0px",
                  paddingRight: "40px",
                  paddingBottom: "0px",
                  paddingLeft: "0px",
                  fontSize: "16px",
                }}
              >
                {food.ingredients}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default PizzaBoxComponent;
